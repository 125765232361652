import { gql } from "graphql-request";

export const SPONSERS_LIST = gql`
  query {
    AcademySponsors{
      name,
      name_ar,
      id,
      logo
    }
  }
`
