import { gql } from "graphql-request";

export const ACADEMY_SPONSERS_INSERT = gql`
    mutation insertSponsor($name: String!, $name_ar: String, $logo: Upload,){
        insertSponsor(insertSponsorInput: {name: $name, name_ar: $name_ar, logo: $logo}){
        id
        }
    }
`
export const ACADEMY_SPONSERS_UPDATE = gql`
    mutation AcademySponsorsUpdate($id: Int,$name: String!, $name_ar: String,$logo: String,){
        AcademySponsorsUpdate(academy_sponsors: {id: $id,name: $name, name_ar: $name_ar, logo: $logo}){
            id
            name
            name_ar
            logo
            created
        }
    }
`

export const ACADEMY_SPONSERS_DELETE = gql`
    mutation deleteSponsor($id: Int!) {
        deleteSponsor(deleteSponsorInput: {id: $id}) {
        status
        }
    }
`
