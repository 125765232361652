<template>
  <va-card>
    <va-card-title>{{ $t('academy_information') }}</va-card-title>
    
    <va-card-content>
      <div style="display:flex">
        <va-breadcrumbs color="primary">
          <va-breadcrumbs-item :label="$t('menu.main')" />
          <va-breadcrumbs-item :label="$t('menu.academy')" />
          <va-breadcrumbs-item :label="$t('menu.information')"/>
        </va-breadcrumbs>
        <div  class="md6">
          <va-button icon="add" color="info" @click="formIsOpen = !formIsOpen" class="mr-4">{{ $t('add') }}</va-button>
        </div>
      </div>
        
      <sponsers-form :selectedRowToEdit="selectedRowToEdit"  :formIsOpen="formIsOpen" @closeForm="closeForm" @rowInserted="insertTableRow" @rowUpdated="upateRow"></sponsers-form>


    </va-card-content>
    
  </va-card>

  <div class="mt-3">
    <div class="flex">
      <va-card stripe stripe-color="primary">
        <va-card-content>
          <div class="">
            <div class="va-table-responsive">
              <va-inner-loading :loading="isLoading">
                <table class="va-table va-table--hoverable">
                  <thead>
                    <tr>
                      <th>{{$t('name')}}</th>
                      <th>{{$t('name_ar')}}</th>
                      <!-- <th>{{$t('descr')}}</th>
                      <th>{{$t('descr_ar')}}</th> -->
                      <th>{{$t('img')}}</th>
                      <th>{{$t('actions')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(sponser, i) in sponsers" :key="sponser.id">
                      <td>{{sponser.name}}</td>
                      <td>{{sponser.name_ar}}</td>
                      <!-- <td>{{sponser.descr}}</td>
                          <td>{{sponser.descr_ar}}</td> -->
                      <td><img :src="imgUrl + '/' + sponser.logo" alt="" style="width: 50px;height: 50px;"></td>
                      <td>
                        <div style="display: flex; justify-content: space-evenly;">
                          <va-button color="info" @click="editRow(sponser, i)" icon="edit" flat />
                          <va-button @click="deleteRow(sponser.id, i)" color="danger" icon="delete" flat />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </va-inner-loading>
            </div>
          </div>
        </va-card-content>
                    
      </va-card>
    </div>
  </div>
</template>

<script>
import { request, GraphQLClient } from 'graphql-request';
import SponsersForm from "@/components/forms/academy/SponsersForm.vue";
import { SPONSERS_LIST } from "@/graphql/queries/academy/sponsers";
import { ACADEMY_SPONSERS_DELETE } from "@/graphql/mutations/academy/sponsers";
export default {
  components: {
    SponsersForm,
  },
  data () {
      return {
        sponsers: [],
        formIsOpen: false,
        selectedRowToEdit: {},
        isLoading: false
      }
  },
  computed: {
    imgUrl(){
      return this.$store.getters.getUrl
    }
  },
  methods: {
    insertTableRow(row){
      this.sponsers.push(row);
      // this.getSponsers();
      this.formIsOpen = false;
    },
    getSponsers(){
      this.isLoading = true;
      request(this.$store.state.appUrl, SPONSERS_LIST).then((data) => {
      this.isLoading = false;
      console.log(data)
      this.sponsers = data.AcademySponsors;
      }).catch((err) => {
        this.isLoading = false;
        this.$swal.fire({
          text:this.$t('error_msg'),
          icon:'error',
          confirmButtonColor: '#039303',
          confirmButtonText: this.$t('ok'),
        })
      });
    },
    closeForm(){
      this.formIsOpen = false
      this.selectedRowToEdit = {}
    },
    upateRow(row){
      this.getSponsers();
      this.selectedRowToEdit = {}
      this.formIsOpen = false;
    },
    editRow(sponser, index){
      console.log(index);
      this.selectedRowToEdit = sponser
      this.formIsOpen = true;
    },
    deleteRow(id, i){
      this.$swal.fire({
        title: `${this.$t('sure?')}`,
        text: this.$t('delete_text'),
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#6e7881',
        confirmButtonColor: '#e42222',
        confirmButtonText: this.$t('confirm'),
        cancelButtonText : this.$t('cancel')
        }).then( (result) => {
        if (result.isConfirmed) {
            const graphQLClient = new GraphQLClient(this.$store.state.appUrl);

            const variables = {
              id
            }
            try{
              graphQLClient.request(ACADEMY_SPONSERS_DELETE, variables).then((data) => {
                
                console.log(JSON.stringify(data, undefined, 2))

                this.getSponsers();
              })

            }catch(err){
              console.log(err);
            }

        }
      })
    }
  },
  created(){
    this.getSponsers();
  }
}
</script>
    
<style>

    
</style>